import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../Assets/navstyle.css'; // Adjust the path if necessary

// import prosspucts from '../../Assets/pdf/prosspucts.pdf';
import syllabus from '../../Assets/pdf/Bedsyallabus.pdf';

function CustomNavbar() {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Item>
            <Nav.Link as={Link} to="/" className="nav-link text-center">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="About Us" id="about-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Introducation">Introduction</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Vision">Vision and Mission</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Chairman">Chairman Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Director">Director Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Principal">Principal Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Faculty" >Our Faculty</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Committeess" >Our Committeess</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Rules-regulations">Rules and regulations</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          
          <Nav.Item>
            <NavDropdown title="Course" id="course-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/D-pharmacy">Overview</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/B-pharmacy">Eligibility & Fee Structure</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/Admission" className="nav-link text-center">Admission</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <NavDropdown title="Admission" id="admission-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Application">Admission Process</NavDropdown.Item>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
              <NavDropdown.Item as={Link} to="/Application">Application Form</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item> */}
          {/* <Nav.Item>
            <NavDropdown title="Facilities" id="course-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Library">Library</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Cafeteria">Cafeteria /Canteen</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Seminar">Seminar Hall</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Recreationroom">Recreation Room</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Recreational">Recreational Areas</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Playground">Playground</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Security">Security</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link as={Link} to="/Facilities" className="nav-link text-center">Facilities</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Gallery" id="gallery-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Gallery">Photo Gallery</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/Video-gallery">Video Gallery</NavDropdown.Item> */}
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Student Corner" id="downloads-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="#">Notifications/Updates</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/Service">Our Service</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/Alumni">Alumni Form</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Grievance">Grievance Cell</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/Results">Results</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/Batch">Student Details</NavDropdown.Item>
              
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Downloads" id="downloads-dropdown" className="nav-link navt text-center">
            <NavDropdown.Item href={syllabus} target="_blank">Syllabus</NavDropdown.Item>
            {/* <NavDropdown.Item href={} target="_blank">Prospectus</NavDropdown.Item> */}
              
              
              {/* <NavDropdown.Item href={form} target="_blank">Admission Form</NavDropdown.Item>
              <NavDropdown.Item href={syllabus} target="_blank">Syllabus</NavDropdown.Item>
              <NavDropdown.Item href={prosspucts} target="_blank">Prospectus</NavDropdown.Item> */}
            </NavDropdown>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link as={Link} to="/Nep" className="nav-link text-center">NEP 2020</Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link as={Link} to="/Contact" className="nav-link text-center">Contact Us</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
