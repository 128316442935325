import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import principalimg from '../../Assets/img/prinicpal.jpg'

function Results() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Student Result</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                  <h3>Topper of The College</h3>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Student's Name</th>
                          <th scope="col">Session</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Sonika Banot</td>
                          <td>2003-2004</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Shalini Sharma</td>
                          <td>2004-2005</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Digpal Singh Bisht</td>
                          <td>2005-2006</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Sanjeev Kr. Sharma</td>
                          <td>2006-2007</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Shakun Mohan Saxena</td>
                          <td>2007-2008</td>
                        </tr>

                        <tr>
                          <th scope="row">6</th>
                          <td>Pathan Shabnam</td>
                          <td>2008-2009</td>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>Vivek Rawat</td>
                          <td>2009-2010</td>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Nitika Dubey</td>
                          <td>2010-2011</td>
                        </tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>Shilpi Chanana</td>
                          <td>2011-2012</td>
                        </tr>
                        <tr>
                          <th scope="row">10</th>
                          <td>Juman Kumari</td>
                          <td>2018-2020</td>
                        </tr>
                        <tr>
                          <th scope="row">11</th>
                          <td>Anuj Khokher</td>
                          <td>2019-2021</td>
                        </tr>
                        <tr>
                          <th scope="row">12</th>
                          <td>Tania Baksti </td>
                          <td>2020-2022</td>
                        </tr>

                        <tr>
                          <th scope="row">13</th>
                          <td>Priya Helme</td>
                          <td>2021-2023</td>
                        </tr>
                        <tr>
                          <th scope="row">14</th>
                          <td>Aarushi Sambyal</td>
                          <td>2022-2024</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
