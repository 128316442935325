import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import principalimg from '../../Assets/img/prinicpal.jpg'

function Dpharmacy() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>BACHELOR OF EDUCATION (B.ED)</h2>
              <h3>OVERVIEW</h3>
           
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>BACHELOR OF EDUCATION</h3>
                    <p>We offers bachelors of education which is an under graduate academic degree which prepares students towards the professional outlook of teaching. It is a 2 years duration course .Students are admitted through entrance examination held by  WITH AN INTAKE CAPACITY OF 100 STUDENTS.</p>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>WHY CHOOSE THIS COURSE?</h3>
                    <ul>
                      <li>Content and pedagogical expertise.</li>
                      <li>Immersive internships- observation and teaching.</li>
                      <li>Emphasis on holistic development.</li>
                      <li>Multifaceted work shop and seminars.</li>
                      <li>Community & Service – Learning opportunities.</li>
                    </ul>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>WHAT WE LEARN?</h3>
                    <ul>
                      <li>Subject – specific knowledge ensuring expertise in academic domains, and pedagogical knowledge for fostering impactful education.</li>
                      <li>Understand educational psychology to gain insights into children’s development and learning process.</li>
                      <li>Educational technology for meeting the evolving needs and demands of 21st century education.</li>
                      <li>Gain insights on curriculum design and assessment practices for effective educational planning and student evaluation.</li>
                      <li>Explore the evolving educational landscape, addressing contemporary matters like multicultural and inclusive education.</li>
                      <li>Acquire knowledge on educational organizational culture, leadership styles and resource management aspects.</li>
                    </ul>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>CAREER PROSPECTS</h3>
                    <ul>
                      <li>Empower young minds through classroom instruction, guiding their educational journeys with care and expertise.</li>
                      <li>Contribute to development of educational content, foster meaningful learning experiences, and shape the future of learning.</li>
                      <li>Lead and transform education with carers in educational administration, enhancing the capacities of schools and institutions.</li>
                      <li>Pathways to develop future educators, promoting professional development, and elevating educational standards.</li>
                      <li>Engage with governmental and non-governmental institutions to promote educational Initiatives, ensuring positive societal change.</li>
                      <li>Drive innovation ineducation technology, creating resolutions that transform and elevate the leading experience.</li>
                    </ul>
                  </div>
                 
                  <div className="doctor-details-biography">
                    <h3>ELIGIBILITY FOR ADMISSION</h3>
                    <p> Candidates with at least 50% marks (45% for SC/ST candidates) either in the Bachelor's Degree(10+2+3 pattern) and/or Master’s Degree in Sciences/Social Sciences/ Humanity,Bachelor’s in Engineering or Technology with specialization in Science and Mathematics with 55%marks or any other qualification equivalent thereto, are eligible for admission to the programme.</p>
                  </div>
                  {/* <div className="doctor-details-biography">
                    <h3>MODULES</h3>
                    <ul>
                      <li>INTRODUCTION TO EDUCATION.</li>
                      <li>CURRICULUM DEVELOPMENT AND ASSESSMENT PRACTICES.</li>
                      <li>PHILOSOPHICAL FOUNDATION OF EDUCATION.</li>
                    </ul>
                    <table className="table">
                     
                      <tbody>
                        <tr>
                          <th >SEM 1</th>
                        </tr>
                        
                        <tr>
                          <th scope="row"></th>
                          
                          <ul>
                            <li>Educational psychology.</li>
                            <li>Pedagogy of subject.</li>
                            <li>Skill enhancement and holistic development.</li>
                            
                            </ul>
                        </tr>
                        
                        <tr>
                          <th >SEM 2</th>
                        </tr>
                        
                        <tr>
                          <th scope="row"></th>
                          <ul>
                            <li>Pedagogy of every subject.</li>
                            <li>School – internship Phase -1.</li>
                            <li>Skill enhancement and holistic development.</li>
                            <li> Service learning and community engagement.</li>
                            </ul>

                        </tr>
                        <tr>
                          <th >SEM 3</th>
                        </tr>
                        
                        <tr>
                          <th scope="row"></th>
                          
                          <ul>
                            <li>School internship Phase-2.</li>
                            <li>Sociological perspective in education.</li>
                            <li>Contemporary education issues.</li>
                            <li>Guidance and counselling.</li>
                            
                            </ul>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dpharmacy;
