import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'


const Committees = () => {
  return (
    <div className='mt-4'>
      <style>{`
        .committee-box {
          background-color: #f9f9f9;
          padding: 20px;
          margin-bottom: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        .committee-title {
          font-size: 1.5rem;
          font-weight: bold;
          color: #333;
          margin-bottom: 15px;
          text-align: center;
        }
        .committee-list {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
        .committee-list li {
          padding: 5px 0;
          color: #555;
        }
      `}</style>

      <div className="container">
        <div className="row">
          {committees.map((committee, index) => (
            <div className="col-lg-6 " key={index}>
              <div className="committee-box">
                <h3 className="committee-title">{committee.name}</h3>
                <ul className="committee-list">
                  {committee.members.map((member, idx) => (
                    <li key={idx}>{member}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const committees = [
  {
    name: 'Admission Committee',
    members: [
      
      'Ms. Arti Sharma (Convener)',
      'Ms. Lovely Manhas',
      'Mr. Karam Chand',
      'Ms. Sakshi Raina',
      'Mr. Amit Sharma',
    ],
  },
  {
    name: 'Examination & Evaluation Committee',
    members: [
      'Dr. Bharti Sharma (Convener)',
      'Ms. Arti Sharma',
      'Ms. Antra Mahajan',
      'Ms. Lovely Manhas',
      'Ms. Tania Jamwal',
    ],
  },
  {
    name: 'Library Committee',
    members: [
      'Ms. Babita Fotedar (Convener)',
      'Ms. Arti Sharma',
      'Ms. Chanchal Shan',
      'Ms. Lovely Manhas',
    ],
  },
  // {
  //   name: 'Anti Ragging Committee',
  //   members: [
  //     'Ms. Lovely Manhas (Convener)',
  //     'Ms. Tania Jamwal',
  //     'Ms. Antra Mahajan',
  //     'Ms. Chanchal Shan',
  //   ],
  // },
  {
    name: 'Student Support/Grievances Committee',
    members: [
      'Dr. Bharti Sharma (Convener)',
      'Ms. Arti Sharma',
      'Ms. Lovely Manhas',
      'Ms. Suman Kumari',
    ],
  },
  // {
  //   name: 'Committee Against Sexual Harassment',
  //   members: [
  //     'Ms. Arti Sharma (Convener)',
  //     'Ms. Lovely Manhas',
  //     'Ms. Suman Kumari',
  //     'Mr. Amit Sharma',
  //   ],
  // },
  {
    name: 'Infrastructure & Maintenance Committee',
    members: [
      'Mr. Karam Chand (Convener)',
      'Ms. Sakshi Raina',
      'Ms. Lovely Manhas',
      'Mr. Jeewan Kumar',
    ],
  },
  {
    name: 'Media and PR',
    members: [
      'Ms. Chanchal Shan (Convener)',
      'Ms. Arti Sharma',
      'Ms. Antra Mahajan',
      'Mr. Amit Sharma',
    ],
  },
 
  {
    name: 'Co-Curricular & Curricular Committee',
    members: [
      'Mr. Amit Sharma (Academic Convener)',
      'Ms. Antra Mahajan (Co-Curricular Convener)',
      'Ms. Suman Kumari',
      'Ms. Arti Sharma',
    ],
  },
  {
    name: 'IQAC',
    members: [
      'Ms. Suman Kumari (Convener)',
      'Ms. Chanchal Shan',
      'Ms. Antra Mahajan',
      'Mr. Amit Sharma',
    ],
  },
  
  {
    name: 'Disciplinary',
    members: [
      'Dr. Bharti Sharma (Convener)',
      'Ms. Lovely Manhas',
      'Ms. Tania Jamwal',
      'Ms. Chanchal Shan',
    ],
  },
  {
    name: 'Alumni & Placement Committee',
    members: [
      'Ms. Arti Sharma (Convener)',
      'Ms. Lovely Manhas',
      'Mr. Amit Sharma',
    ],
  },
  
];

export default Committees;