import React, { useState } from 'react';
import '../../Assets/CardSlider.css'; // Create this CSS file for custom styles

const slides = [
    { title: "LIBRARY", description: "Databases, E-books, E- journals, Magazines, Journals, Open access materials, Question papers" },
    { title: "Cafeteria/Canteen", description: "KAWA COLLEGE OF EDUCATION has cafeteria and canteen seating up to 100 individuals, A variety of hygienically prepared delicious food is served whether it is breakfast,lunch, a quick snack or a cup of coffee, one is looking for the canteens. Where they should go the facilities are easily accessible by anyone, visitor included the sight of hundreds of students spread, enjoying their food in groups, is pleasant indeed." },
    { title: "Seminar Hall", description: "The campus houses seminar halls, which on variably accommodate 50-100 people at time. The facilities cater to the efficient organisation of on campus such as meetings, convocation, guest lectures, presentations short term training programs, workshops, conferences, cultural activities, celebrations and a lot more." },
    { title: "Recreation Room", description: "The college emphasis student recreation and well- being to accomplish this, the campus houses, recreation rooms, when the students can spend time playing task tennis, carom, chess and variety of other games." },
    { title: "Recreational Areas", description: "The sprawling KAWA COLLEGE OF EDUCATION campus have plenty of nooks and corners, green space and shady trees where ideation, analysis, jam sessions, board games and innovative ideas happen. There are places for interactions to intellectual debates, dance, song, play theatre practices and special day celebrations." },
    { title: "Playground", description: "This is a sixth description" },
    { title: "Security", description: "Dedicated and disciplined round the clock security is the hallmark of the campus. They are disciplined,polite and at the same time duty bound to strict rules and regulations, which they enforce on campus. They are the custodians of peace and security on campuses and the student with their mental and physical presence in their posts." },
];

const CardSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === slides.length - 3 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? slides.length - 3 : prev - 1));
    };

    return (
        <div className="slider-container">
            <button className="prev-button" onClick={prevSlide}>Prev</button>
            <div className="slider" style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}>
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div className="card">
                            <h2>{slide.title}</h2>
                            <p>{slide.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <button className="next-button" onClick={nextSlide}>Next</button>
        </div>
    );
};

export default CardSlider;
