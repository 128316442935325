import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import principalimg from '../../Assets/img/prinicpal.jpg'

function Results() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Student Details</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                  {/* <h3>TEACHING FACULTY DETAILS</h3> */}
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Batch</th>
                          <th scope="col">Total Student</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>2018-2020</td>
                          <td>95</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>2019-2021</td>
                          <td>63</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>2020-2022</td>
                          <td>39</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>2021-2023</td>
                          <td>47</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>2022-2024</td>
                          <td>45</td>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>2023-2025</td>
                          <td>48</td>
                        </tr>
                        
                       
                      </tbody>
                    </table>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
